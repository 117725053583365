import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Box, withStyles, Container, Fade } from "@material-ui/core";
import { generatorServices } from "../_services";
import { compose } from "redux";
import { Helmet } from "react-helmet";
import { PostView } from "../Components";
import { history } from "../_helpers";
import { withFirebase } from "../Firebase";

class Post extends Component {
  state = {
    postCode: "",
    post: "",
  };

  componentDidMount() {
    const postKey = this.props.match.params.id;
    this.setState({ postKey: postKey });

    const { firebase } = this.props;

    firebase.getPost(
      postKey,
      function (post) {
        if (post) {
          post.key = postKey;
          this.setState({ post: post });
        } else {
          this.handleBack();
        }
      }.bind(this)
    );
  }

  handleRefreshPost(e) {
    const { post } = this.state;
    const { firebase } = this.props;
    const newPost = generatorServices.generateNewPost(post.input);
    console.log(newPost);
    const postKey = firebase.doPost(newPost);

    firebase.logEvent("action_reload_post", { button: "reload_post" });

    history.replace("/Post/" + postKey);
    window.location.reload();
  }

  handleBack(e) {
    const { firebase } = this.props;
    firebase.logEvent("action_back", { button: "back" });
    history.push("/");
    window.location.reload();
  }

  handleShareTwitter(e) {
    const { firebase } = this.props;
    firebase.logEvent("action_share_twitter", { button: "share_twitter" });
  }

  handleShareFacebook(e) {
    const { firebase } = this.props;
    firebase.logEvent("action_share_facebook", { button: "share_facebook" });
  }

  render() {
    const { classes } = this.props;
    const { post } = this.state;

    if (post) {
      return (
        <Container>
          <Helmet>
            <meta charSet="utf-8" />
            <title>הביביבוט - מחולל הסטטוסים הרשמי של הימין!</title>
            <meta property="og:sitename" content="הביביבוט" />
            <meta
              property="og:title"
              content="הביביבוט - מחולל הסטטוסים הרשמי של הימין!"
            />

            <meta property="description" content={post.text} />
            <meta property="og:description" content={post.text} />
          </Helmet>

          <Fade in={true}>
            <PostView
              post={post}
              enableShare={true}
              onRefresh={this.handleRefreshPost.bind(this)}
              onBack={this.handleBack.bind(this)}
            />
          </Fade>
        </Container>
      );
    } else {
      return (
        <Box className={classes.reloadBox} fontSize="fontSize">
          טוען סטטוס...
        </Box>
      );
    }
  }
}

const styles = (theme) => ({
  reloadBox: {
    minWidth: "300px",
    position: "fixed",
    textAlign: "center",
    padding: "20px",
    left: "50%",
    top: "10%",
    transform: "translate(-50%, 0)",
    outlineWidth: "2px",
    outlineColor: "#000000",
    borderRadius: "15px",
    boxShadow: "0 0 5pt 0pt #EEEEEE",
  },
});

function mapStateToProps(state) {
  return {};
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(withRouter(withFirebase(Post)));
