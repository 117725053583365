import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyB6WmxNRHcYpI6IdU6r7DAPrpyego_xRQM",
  authDomain: "fake-generator.firebaseapp.com",
  databaseURL: "https://fake-generator.firebaseio.com",
  projectId: "fake-generator",
  storageBucket: "fake-generator.appspot.com",
  messagingSenderId: "991524184061",
  appId: "1:991524184061:web:b4b9822ef498cac43d8394",
  measurementId: "G-QB7L31K0H9",
};

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);
    this.auth = app.auth();
    // this.isLogedIn = false;
    this.database = app.database();
    this.analytics = app.analytics();
  }

  analytics() {
    return this.analytics;
  }

  logEvent(category, data) {
    this.analytics.logEvent(category, data);
  }

  doAnonymusLogin(callback) {
    this.auth.signInAnonymously().catch(function (error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log("anonymus login error - " + errorCode + " : " + errorMessage);
      // ...
    });

    this.auth.onAuthStateChanged(function (user) {
      if (user) {
        // User is signed in.
        //this.isLogedIn = user.isAnonymous;
        this.uid = user.uid;

        // ...
      } else {
        // User is signed out.
        // ...
        this.uid = null;
      }
      // ...
      if (callback) {
        callback();
      }
    });
  }

  doPost(postData, isNew = false) {
    var newPostKey = this.database.ref().child("posts").push(postData).key;

    var updates = {};
    updates["/posts/" + newPostKey] = postData;
    //  updates["/inputs/" + postData.input] = "";

    if (isNew) {
      this.database
        .ref()
        .child("/inputs/" + postData.input)
        .transaction(function (currentClicks) {
          return (currentClicks || 0) + 1;
        });
    }

    this.database.ref().update(updates);

    // this.database.ref("posts/" + newPostKey).set(postData);
    return newPostKey;
  }

  getPost(postKey, callback) {
    this.database.ref("/posts/" + postKey).once("value", function (snapshot) {
      callback(snapshot.val());
    });
  }

  getRecomondations(count, callback) {
    this.database
      .ref("/inputs/")
      .limitToLast(100)
      .once("value", function (snapshot) {
        let keys = [];
        snapshot.forEach(function (childSnapshot) {
          var childKey = childSnapshot.key;
          keys.push(childKey);
        });

        const sample = keys
          .map((x) => ({ x, r: Math.random() }))
          .sort((a, b) => a.r - b.r)
          .map((a) => a.x)
          .slice(0, count);

        callback(sample);
      });
  }

  getFeedPosts(count, callback) {
    this.database
      .ref("/posts/")
      .limitToLast(50)
      .once("value", function (snapshot) {
        let posts = [];
        snapshot.forEach(function (childSnapshot) {
          var post = childSnapshot.val();
          posts.push(post);
        });

        const sample = posts
          .map((x) => ({ x, r: Math.random() }))
          .sort((a, b) => a.r - b.r)
          .map((a) => a.x)
          .slice(0, count);

        callback(sample);
      });
  }
}

export default Firebase;
