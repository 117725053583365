import React from "react";
import "./Views";
import { Main, Post } from "./Views/";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Footer from "./Components/Footer";
import Firebase, { FirebaseContext } from "./Firebase";

function App() {
  return (
    <FirebaseContext.Provider value={new Firebase()}>
      <BrowserRouter>
        <Switch>
          <Route path="/post/:id">
            <Post />
          </Route>
          <Route path="/">
            <Main />
          </Route>
        </Switch>
        <Footer />
      </BrowserRouter>
    </FirebaseContext.Provider>
  );
}

export default App;
