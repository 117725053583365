import React, { Component } from "react";
import {
  Box,
  withStyles,
  IconButton,
  Typography,
  Container,
} from "@material-ui/core";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { UserPanel } from "../Components";
import CachedIcon from "@material-ui/icons/Cached";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import { connect } from "react-redux";
import { compose } from "redux";
import { withFirebase } from "../Firebase";

class PostView extends Component {
  handleShareTwitter(e) {
    const { firebase } = this.props;
    firebase.logEvent("action_share_twitter", { button: "share_twitter" });
  }

  handleShareFacebook(e) {
    const { firebase } = this.props;
    firebase.logEvent("action_share_facebook", { button: "share_facebook" });
  }

  render() {
    const { post, onRefresh, onBack, classes, enableShare } = this.props;
    const shareUrl = post && "www.bibibot.net/Post/" + post.key;

    const trail = post && post.text.length > 80 ? "..." : "";
    const sharePost = post && post.text.substring(0, 80) + trail;

    return (
      <Box className={classes.post} fontSize="fontSize">
        <UserPanel />
        <Container>
          <Typography variant="h5" component="h2">
            {post && post.text}
          </Typography>
        </Container>
        <br />
        <Container>
          {enableShare && (
            <span>
              <FacebookShareButton
                url={shareUrl}
                quote={sharePost}
                onClick={this.handleShareFacebook.bind(this)}
              >
                <IconButton>
                  <FacebookIcon />
                </IconButton>
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                title={sharePost}
                onClick={this.handleShareTwitter.bind(this)}
              >
                <IconButton>
                  <TwitterIcon />
                </IconButton>
              </TwitterShareButton>
            </span>
          )}
          <span>
            {onRefresh && (
              <IconButton onClick={onRefresh}>
                <CachedIcon />
              </IconButton>
            )}
            {onBack && (
              <IconButton onClick={onBack}>
                <ArrowBackIcon />
              </IconButton>
            )}
          </span>
        </Container>
      </Box>
    );
  }
}
const styles = (theme) => ({
  post: {
    maxWidth: "600px",
    // position: "fixed",
    textAlign: "right",
    // padding: "20px",
    // left: "50%",
    // top: "10%",
    // transform: "translate(-50%, 0)",
    padding: "10px",
    outlineWidth: "2px",
    outlineColor: "#000000",
    borderRadius: "15px",
    boxShadow: "0 0 5pt 0pt #DDDDDD",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "30px",
    marginBottom: "30px",
    justifyContent: "center",
  },
  postText: {},
  reloadBox: {
    minWidth: "300px",
    position: "fixed",
    textAlign: "center",
    padding: "20px",
    left: "50%",
    top: "10%",
    transform: "translate(-50%, 0)",
    outlineWidth: "2px",
    outlineColor: "#000000",
    borderRadius: "15px",
    boxShadow: "0 0 5pt 0pt #DDDDDD",
  },
});

function mapStateToProps(state) {
  return {};
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(withFirebase(PostView));
