import React, { Component } from "react";
import { withFirebase } from "../Firebase";
import { PostView } from "../Components";

class FeedView extends Component {
  render() {
    const { feed } = this.props;

    return feed.map((post, i) => {
      return <PostView post={post} enableShare={false} key={i} />;
    });
  }
}

export default withFirebase(FeedView);
