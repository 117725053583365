import React, { Component } from "react";
import {
  Box,
  TextField,
  Button,
  withStyles,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { generatorServices } from "../_services";
import { history } from "../_helpers";
import { compose } from "redux";
import { withFirebase } from "../Firebase";
import { FeedView } from "../Components";

class Main extends Component {
  state = {
    input: "",
  };

  componentDidMount() {
    const { firebase } = this.props;
    firebase.doAnonymusLogin(
      function () {
        firebase.getRecomondations(
          3,
          function (recomondations) {
            this.setState({ recomondations });
          }.bind(this)
        );

        firebase.getFeedPosts(
          3,
          function (feed) {
            this.setState({ feed });
          }.bind(this)
        );
      }.bind(this)
    );
  }

  render() {
    const { classes } = this.props;
    const { recomondations, input, feed } = this.state;

    return (
      <Box className={classes.box}>
        <h1>מי אשמים במצב?</h1>
        <Box>
          <ThemeProvider theme={theme}>
            <div dir="rtl">
              <TextField
                dir="rtl"
                id="input"
                value={input}
                label="מי אשמים במצב?"
                variant="outlined"
                className={classes.inputField}
                onChange={this.handleInputChange.bind(this)}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={this.handlePost.bind(this)}
                className={classes.postButton}
              >
                שלח
              </Button>
            </div>
          </ThemeProvider>
        </Box>

        <br />
        {recomondations &&
          recomondations.map((key, i) => {
            return (
              <Button
                key={key}
                color="primary"
                onClick={() => this.handleSetRecomondation(key)}
              >
                {key}
              </Button>
            );
          })}

        <br />

        {feed && feed.length > 0 && <FeedView feed={feed} />}
      </Box>
    );
  }

  handleSetRecomondation(key) {
    const { firebase } = this.props;
    firebase.logEvent("action", { button: "recomendation", value: key });
    this.setState({ input: key });
  }

  handleInputChange(e) {
    this.setState({ input: e.target.value });
  }

  handlePost(e) {
    const { input } = this.state;
    const { firebase } = this.props;

    firebase.logEvent("action", { button: "post", input: input });

    let isValid = false;
    if (input && input.trim(" ").length > 0) {
      isValid = true;
    }

    if (isValid) {
      if (input && input.length > 0) {
        const post = generatorServices.generateNewPost(input);
        const postKey = firebase.doPost(post, true);

        history.push("/Post/" + postKey);
        window.location.reload();
      }
    }
  }
}

const theme = createMuiTheme({
  direction: "rtl", // Both here and <body dir="rtl">
});

const styles = (theme) => ({
  box: {
    flexDirection: "row",
    alignSelf: "flex-start",

    // position: "fixed",
    textAlign: "center",
    //left: "50%",
    //top: "30%",
    // transform: "translate(-50%, -50%)",
    margin: "auto",
    maxWidth: "90%",
  },
  inputField: {
    direction: "rtl",
    dir: "rtl",
    textAlign: "right",
    flip: true,
  },
  postButton: {
    height: "55px",
    width: "120px",
    marginLeft: "20px",
    marginRight: "10px",
    fontSize: "26px",
  },
});

function mapStateToProps(state) {
  return {};
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(withRouter(withFirebase(Main)));
