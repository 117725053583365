import React, { Component } from "react";
import { withStyles, Container } from "@material-ui/core";

class Footer extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Container className={classes.footer}>
        זהו אתר סאטירי, אין ליחס לתוכן בו שום משמעות. הכל נעשה בהומור , אז תניחו
      </Container>
    );
  }
}
const styles = (theme) => ({
  footer: {
    // position: "fixed",
    left: "0px",
    bottom: "0px",
    width: "100%",
    textAlign: "center",
    paddingBottom: "10px",
    paddingLeft: "0px",
    paddingRight: "0px",
    fontSize: "12px",
    maxWidth: "100%",
    marginTop: "30px",
  },
});

export default withStyles(styles)(Footer);
