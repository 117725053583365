import React from "react";
import { Avatar } from "@material-ui/core";
import avatar1 from "../Resources/avatar1.png";

export default function UserPanel() {
  return (
    <div>
      <Avatar alt="ביביבוט" src={avatar1} />
      ביביבוט
    </div>
  );
}
