import { templates } from "../_texts";
import { Base64 } from "js-base64";

export const generatorServices = {
  generatePostURL,
  generatePost,
  createPostCode,
  decodePostCode,
  reloadPost,

  generateNewPost,
};

function generateNewPost(input) {
  const post = {
    input,
    template: Math.floor(Math.random() * templates.length),
    version: "0.1",
    created: new Date().toLocaleString(),
    text: this.generatePost(input),
    bot: Math.random(),
  };
  return post;
}

function generatePostURL(input) {
  return input;
}

function generatePost(input) {
  var template = templates[Math.floor(Math.random() * templates.length)];
  var post = template.replace(/@INPUT/g, input);
  console.log("post: " + post);
  return post;
}

function reloadPost(postCode) {
  const decodedString = Base64.decode(postCode);
  const postCodeData = JSON.parse(decodedString);
  const input = postCodeData.input;
  return createPostCode(input);
}

function decodePostCode(postCode) {
  const decodedString = Base64.decode(postCode);
  const postCodeData = JSON.parse(decodedString);
  const template = templates[postCodeData.template];
  const input = postCodeData.input;
  const post = template.replace(/@INPUT/g, input);
  return post;
}

function createPostCode(input) {
  const postCode = {
    input: input,
    template: Math.floor(Math.random() * templates.length),
  };

  const encodedString = Base64.encode(JSON.stringify(postCode, true));
  return encodedString;
}
