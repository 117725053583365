const templates = [
  "עלי תאנה ימנים יקרים בתקשורת המיינסטרים. תמשיכו לירוק על נתניהו. תמשיכו לשתוק לנוכח ההפיכה של @INPUT ופשעיו הרבים. אתם כורתים את הענף שעליו אתם יושבים. אתם חיים בסרט אם אתם לא חושבים שהם יעברו אליכם מיד אחרי שיסיימו עם נתניהו.",
  "תזכרו שבנט אמר @INPUT לא מעניין עכשיו.",
  "@INPUT שמנסים לשלוט במדינה נגד נבחרי הציבור זה אנטי דמוקרטי.",
  "האירוע הביטחוני הכי מסוכן למדינה שאני מכיר היום, זה התנהלות של @INPUT שמובילים למהפכה שלטונית לצד מפגיני יש עתיד, האפסנאי ומרצ. הם מביאים את העם לסף רתיחה רק בגלל שאינם מכבדים את הדמוקרטיה ואת חוקיה. האירוע המסוכן לביטחון המדינה, הוא מתוך המדינה ומוסדותיה!",
  "בית הנשיא יארח את תומכי @INPUT ותנועות החרם על ישראל?",
  "השותפים הטבעיים? מסתבר שכבר מאז 2018 @INPUT משתפים פעולה עם ה-BDS",
  "מי מממן את כל הדבר הזה??????????? במקום לשכור בניין ולפתוח בית תמחוי לנזקקים, משקיעים כסף בדבר הנורא הזה שנקרא @INPUT שהוא לא פחות מביטול רצון הבוחר!",
  "לדעתי הגיע הזמן לבדוק האם בגצ יאשר הפגנה כמו בבלפור ליד הבית של @INPUT... אם לגיטימי אז בתאבון!",
  "קבלו את @INPUT צועקים על נתניהו: בוגד! הוא בוגד! מנהיגי @INPUT מגנים? קוראים לחדול? @INPUT אלימים וצבועים!!",
  "אה חשבתי שההתלבטות של @INPUT היא לפי מה טוב למדינה. זה הכל היה הקמפיין שלכם ב12 בשלוש מערכות הבחירות לא?",
  "חחחחח בקלפי שוב ושוב הוא מקבל את מספר המנדטים הגדול ביותר רק שהעובדות לא יבלבלו את השקרים של @INPUT",
  "זה הפרטנרים שלך בשביל המטרה הקדושה להעיף את ביבי ו*אוחנה*. חכה שתשמע מה @INPUT אומרים במסגדים על הקהילה...",
  "@INPUT ארורים שגזלו חצי מאדמות המדינה על חשבון עיירות הפיתוח. שחררו את האסי לתושבי בית שאן, לפני שאתם מטיפים!",
  "@INPUT מסירים את השאריות האחרונות של הדמוקרטיה בישראל , ומכוננים פה משטר פקידים טוטאליטארי.",
  "הסכם אוסלו הארור היה אירוע האיוולת המדיני-ביטחוני החמור ביותר בתולדות הציונות, ואירוע איוולת מהחמורים ביותר בתולדות האנושות. תוצר של קונספציה משיחית והפקרות מוסרית. ו@INPUT היום עדיין חושבים עליו כמשהו שקשור לתקווה. הזוי. תהום בלתי ניתנת לגישור.",
  "@INPUT בתמונות המצורפות ופוסטים, בהם ידעו עוד *לפני* הגשת הכתב אישום נגד נתניהו (מראה שהכל היה משחק מכור מראש). וגם מביעים מה הם חושבים על נתניהו מבחינה פוליטית.",
  "2000 שנה העם היהודי זוכר לשמצה את בר- קמצא שבגללו חרב הבית. יתכן שגם בעוד 2000 שנה יהודים יזכרו את @INPUT מאותה סיבה.",
  "אתם קולטים כמה כסף עולה האנרכיה הזאת???? בעלי הון אפלים  ו@INPUT מושכים פה בחוטים , כדי להביא לקץ המדינה היהודית.",
  "קורא לכל הציבור החרדי להחרים כל מוצר ש@INPUT משווקים!",
  "@INPUT כולם סמרטוטים שחורים של הפדופיל ברח ברח ושל הקרן הפדופילית להשמדת ישראל של סורוס היודנראט הראשי.",
  "@INPUT משחקים בנדמה לי. ראש ממשלה בוחרים בקלפי. תלמדו להפסיד בכבוד!",
  "אין מאחורי זה שום דבר! ריק כמו כל @INPUT בישראל. @INPUT בארץ בסך הכל נמלה קטנה ופצועה שעושה רעש כאילו זה מינימום מלך החיות ברגע שלא מהדהד ולא נגדיל זה ישאר בפרופורציות הנכונות",
  "אל תשכחו דבר אחד: הימין בשלטון: אלימות מינורית בהפגנות!  @INPUT בשלטון: אוטובוסים מתפוצצים! טילים על מרכז הארץ משטחים של המדינה הפלסטינית שחמאס השתלט עליה! מה הייתם מעדיפים?",
  "זה מוכיח ששום ערך ש@INPUT מדברים בשמו - לא נועד לשימוש אישי, רק לכפות על אחרים. @INPUT עצמם ריקים מכל ערך למעט ערך הכוחניות הצרופה . מה שמציב את @INPUT במקום נמוך מבבונים בשרשרת המזון של האבולוציה כי אפילו פרימטים מנהלים היררכיות יותר מורכבות מהיררכיות כוח גרידא",
  'כמו תמיד, הפמיניזם של @INPUT תמיד מתנגש בקיר כאשר מדובר בנשים שאינן חלק מהמחנה של @INPUT. גם במקרה של שרה נתניהו, אין גבולות, אין מצפון, הכל מותר. כמה רוע. אח"כ הם ידברו על הסתה ושוביניזם. צבועים',
  "תפסיקו לבלבל לי מימין את המוח על שנאת חינם, יא חבורת נודניקים ונמושות. קודם כל, שנאות בפוליטיקה יש תמיד ובכל מקום - אלה החיים הדמוקרטיים. בזה אתם נודניקים. מה שיש כאן זה @INPUT קיצונים שעושים הפגנות רועשות, ויצירת אווירת השנאה היא מקצועית. למה נמושות? תיאבקו בחזרה במקום לבלבל ת'מוח.",
  "כנופיות תג מחיר של @INPUT משתוללות בערים. ותגובת אלו שזעקו על כתובת בקיר מסגד: 0 הם תוקפים ומרביצים ויורקים והתקשורת מגבה. ואז אם לקוי בנפשו או מישהו שיותקף יאבד את שלוות רוחו ויחזיר להם. כל התקשורת הצבועה תשאג: אלימות. מתחייב פה מראש לא לגנות איש. ומקווה שגם הימין יבין",
];

export default templates;
